<template>
  <div>
    <Form 
      :menuContentID="content.MenuContentID"
      :locale="locale"
      :extra="extra"
      :onClose="handleCloseForm"
      :onSave="handleSave"
      :onDelete="handleDelete"
    />
    <v-btn
      @click="handleAdd"
      color="primary"
      block
      style="margin: 40px 0 20px 0;"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="extras"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:item.ExtraID="{ item }">
        <a
          @click="handleEdit(item)"
        >
          {{ getTranslation(item.Name, locale) }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import utils from '@/services/utils'
import apiDishOrders from '@/services/apiDishOrders'
import Form from './Form'
export default {
  components: {
    Form,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    extra: null,
    extras: [],
  }),
  computed: {
    headers () {
      return [
          {
            text: this.$t('dtouch.dishOrders.extras.name', this.locale),
            align: 'start',
            sortable: false,
            value: 'ExtraID',
          },
          { text: this.$t('dtouch.dishOrders.extras.price', this.locale), value: 'Price' }
        ]
    },
    contentID () {
      return this.content && this.content.MenuContentID ? this.content.MenuContentID : null
    },
    isNew () {
      return this.extraID === 'new'
    },
  },
  watch: {
    'content.MenuContentID' () {
      this.handleGetExtras()
    },
  },
  mounted () {
    this.handleGetExtras()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetExtras () {
      if (!this.contentID) return
      apiDishOrders.getExtras(this.contentID) 
        .then((response) => {
          this.extras = response.map(item => {
            item.Name = item.Name ? JSON.parse(item.Name) : { es: '' }
            return item
          })
        })
    },
    handleAdd () {
      this.extra = {
        ExtraID: 'new',
        MenuContentID: this.contentID,
        Name: { es: '' },
        Price: null,
      }
    },
    handleEdit (v) {
      this.extra = v
    },
    handleDelete (v) {
      this.extras = this.extras.filter(x => x.ExtraID !== v)
      apiDishOrders.crudExtras(this.contentID, this.extras) 
        .then(() => {
          this.handleGetExtras()
          this.handleCloseForm()
        })
    },
    handleSave (v) {
      let aux = JSON.parse(JSON.stringify(this.extras))
      if (!this.isNew) aux = aux.filter(x => x.ExtraID !== v.ExtraID)
      aux.push(v)
      apiDishOrders.crudExtras(this.contentID, aux) 
        .then(() => {
          this.handleGetExtras()
          this.handleCloseForm()
        })
    },
    handleCloseForm () {
      this.extra = null
    },
  },
}
</script>

