<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{ $t('dtouch.dishOrders.extras.title', locale)}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                style="padding: 0 25px;"
              >
                <translate
                  type="text"
                  v-model="name"
                  :languages="languages || Languages"
                  :label="$t('dtouch.food.name')"
                  @input="handleChangeIsModified"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  :label="$t('dtouch.food.price')"
                  v-model="price"
                  @input="handleChangeIsModified"
                  hide-details
                  :outlined="isDview"
                  style="margin-top: 0;padding-top: 0;"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="handleDelete"
            color="red"
            dark
          >
            {{ $t('common.delete', locale) }}
          </v-btn>
          <v-btn
            @click="handleClose"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    menuContentID: {
      type: String,
      required: true,
    },
    extra: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    name: { es: '' },
    price: null,
  }),
  computed: {
    ...mapState('app',['languages', 'Languages']),
    isDview () {
      return this.$store.state.app.isDview
    }
  },
  watch: {
    'extra.ExtraID' () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.extra) {
        this.name = this.extra.Name
        this.price = this.extra.Price
      }
      this.dialog = this.extra !== null
    },
    handleChangeIsModified () {
    },
    handleClose () {
      this.onClose()
    },
    handleDelete () {
      this.onDelete(this.extra.ExtraID)
    },
    handleSave () {
      this.onSave({
        MenuContentID: this.menuContentID,
        ExtraID: this.extra.ExtraID,
        Name: this.name,
        Price: this.price,
      })

      this.handleClose()
    },
  },
}
</script>

